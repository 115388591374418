<script setup>
    import PersonLookup from 'o365.vue.components.PersonsLookup.vue';
    import * as DataObjects from 'arena.vue.components.AttributesDataObjects.js';
    import OOrgunitLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import { ref, toRef } from 'vue';
    import { getOrCreateDataObject, getDataObjectById } from 'o365.vue.ts'

    const props = defineProps({
        dataObject: Object,
        showAttributes: { default: true },
        customClass: { type: String, required: false, default:'col-lg-3' },
        requiredOrNotEmpty: { default: false },
        emptyAndNotRequired: { default: false }
    })
    const requiredFields = ref("");
    const hiddenFields = ref("");

    window.dsDocument = props.dataObject.data;

    const dsClassificationCode = getOrCreateDataObject(DataObjects.dsClassificationCode),
    dsReviewClasses = getOrCreateDataObject(DataObjects.dsReviewClasses),
    dsTopics = getOrCreateDataObject(DataObjects.dsTopics),
    dsProjectCodes = getOrCreateDataObject(DataObjects.dsProjectCodes),
    dsDisciplines = getOrCreateDataObject(DataObjects.dsDisciplines),
    dsProjectPhases = getOrCreateDataObject(DataObjects.dsProjectPhases),
    dsMainAreas = getOrCreateDataObject(DataObjects.dsMainAreas),
    dsPersons = getOrCreateDataObject(DataObjects.dsPersons),
    dsOrgUnitsLkp = getOrCreateDataObject(DataObjects.dsOrgUnitsLkp),
    dsSubProjects = getOrCreateDataObject(DataObjects.dsSubProjects),
    dsMetaLookup =  getOrCreateDataObject(DataObjects.dsMetaLookup),
    dsMetaFields =  getOrCreateDataObject(DataObjects.dsMetaFields),
    dsCodesLookup =  getOrCreateDataObject(DataObjects.dsCodesLookup),
    dsCodeTypes =  getOrCreateDataObject(DataObjects.dsCodeTypes),
    dsCompanies =  getOrCreateDataObject(DataObjects.dsCompanies),
    dsPlannedChangeLkp =  getOrCreateDataObject(DataObjects.dsPlannedChangeLkp),
    dsDistReqTypes =  getOrCreateDataObject(DataObjects.dsDistReqTypes),
    dsDocumentPackages =  getOrCreateDataObject(DataObjects.dsDocumentPackages),
    dsExtProStatuses =  getOrCreateDataObject(DataObjects.dsExtProStatuses),
    dsExtProcesses =  getOrCreateDataObject(DataObjects.dsExtProcesses),
    dsVoidReasons =  getOrCreateDataObject(DataObjects.dsVoidReasons),
    dsRetentionCodes =  getOrCreateDataObject(DataObjects.dsRetentionCodes),
    dsFreeTextLkp =  getOrCreateDataObject({
        id: 'dsFreeTextLkp',
        viewName: 'aviw_Arena_FreeTextLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Domain_ID",
            masterField: "Domain_ID",
            operator: "equals"
        }],
        fields:
            [{name: "FreeText", type: "string" }]
    }),
    dsErrors = getOrCreateDataObject({
        id: 'dsErrors',
        viewName: 'atbv_Arena_DocumentsErrors',
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [{name: "Error", type: "string"},
            {name: "FieldName", type: "string" }]
    }),
    dsWBS = getOrCreateDataObject({
        id: 'dsWBS',
        viewName: 'aviw_Complete_WBS',
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        fields:
            [{name: "ID", type: "number"},
            {name: "Name", type: "string" },
            {name: "Title", type: "string" },
            {name: "Parent", type: "string" },
            {name: "NameAndTitle", type: "string" }],
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "OrgUnit_ID",
            masterField: "CostProject_ID",
            operator: "equals"
        }],
    })

    dsMetaFields.load();
    dsCodeTypes.load();
    dsErrors.load();

    function classificationCodesLkpWhereClause(){
        return "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL";
    }
    function projectCodesLkpWhereClause(){
        return "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'";
    }
    function disciplinesLkpWhereClause(){
        return "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL";
    }
    function mainAreasLkpWhereClause(){
        return "(DocumentGroup_ID = " + props.dataObject.current.DocumentGroup_ID + " OR DocumentGroup_ID IS NULL) AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]";
    }
    function involvedContractsWhereClause(){
        return "[IdPath] LIKE '" + props.dataObject.current.OrgUnitIdPath + "%'";
    }
    function subProjectsWhereClause(){
        return "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'";
    }
    function topicsWhereclause(){
        return "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'";
    }
    function getMetaWhereClause(index){
        return "MetaType = 'Meta"+index+"' AND IdPath LIKE '" + props.dataObject.current.OrgUnitIdPath + "'";
    }
    function getDocumentPackagesWhereClause(){
        return `'${props.dataObject.current.OrgUnitIdPath}' LIKE IdPath`;
    }

    function getCodesWhereClause(index){
        return "Seq = 'Code"+index+"' AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'";
    }

    function selectCheckBoxValues(pSel, pColumn, pDO){
        let temp = '';
        if(pSel){
            pSel.map((item) => {
                temp += item.Name + ', ';
            })
        }else{
            getDataObjectById(pDO).data.forEach(row => {
                row.isSelected = false;
            });
        }
        props.dataObject.current[pColumn] = temp.slice(0, -2);
        props.dataObject.save();
    }

    function selectInvolvedContractsbeforeOpen() {
        if(!props.dataObject.current.InvolvedContracts)
            return;

        const testArr = props.dataObject.current.InvolvedContracts.split(', ');
        dsOrgUnitsLkp.data.forEach(row => {
            if (testArr.indexOf(row.Name) !== -1) {

                row.isSelected = true;

            }else{
                row.isSelected = false;
            }

        });
    }
    function selectsubProjectsbeforeOpen() {
        if(!props.dataObject.current.SubProjects)
            return;

        const testArr = props.dataObject.current.SubProjects.split(', ');
        dsSubProjects.data.forEach(row => {

            if (testArr.indexOf(row.Name) !== -1) {

                row.isSelected = true;

            }else{
                row.isSelected = false;
            }

        });
    }

    function getRequiredFields(){
        requiredFields.value = {}
        dsErrors.data.map((row)=>{
            if(!props.dataObject.current[row.FieldName]){
                requiredFields.value[row.FieldName] = true;
                requiredFields.value[row.FieldName+'Error'] = row.Error;
            }
        })
    }
    props.dataObject.on('AfterSave',() => {
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
        dsErrors.load().then(()=>{
            getRequiredFields();
        });
    })
    props.dataObject.on('DataLoaded', function(){
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
    })
    dsErrors.on('DataLoaded', () => {
        getRequiredFields();
    })
    
    dsOrgUnitsLkp.on('DataLoaded', function(){
        selectInvolvedContractsbeforeOpen();
    })
    dsSubProjects.on('DataLoaded', function(){
        selectsubProjectsbeforeOpen();
    })

    window.dataObject = props.dataObject.current;
</script>

<template>
    <div class="row accordion-body">
        <div v-if="!props.requiredOrNotEmpty" class="form-group col-12 mb-2">
            <input type="text" class="form-control" placeholder="Search for attributes...">
        </div>
        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.DocumentGroup && !requiredFields['DocumentGroup'])) && (!props.requiredOrNotEmpty || (props.dataObject.current.DocumentGroup || requiredFields['DocumentGroup'])) && !hiddenFields.includes('DocumentGroup')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Document Group')}}</label>
            <input class="form-control form-control-sm" v-model="props.dataObject.current.DocumentGroup" disabled>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ContractorDocumentNo && !requiredFields['ContractorDocumentNo'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ContractorDocumentNo || requiredFields['ContractorDocumentNo'])) && !hiddenFields.includes('ContractorDocumentNo')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Contractor Document No')}}</label>
            <input  type="text" class="form-control form-control-sm" :class="{'border border-danger':requiredFields['ContractorDocumentNo'] && !props.dataObject.current.ContractorDocumentNo}" :title="requiredFields['ContractorDocumentNoError']" v-model="props.dataObject.current.ContractorDocumentNo" @blur="props.dataObject.save()">
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ClientDocumentNo && !requiredFields['ClientDocumentNo'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ClientDocumentNo || requiredFields['ClientDocumentNo'])) && !hiddenFields.includes('ClientDocumentNo')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Client Document Number')}}</label>
            <input  type="text" class="form-control form-control-sm" :class="{'border border-danger':requiredFields['ClientDocumentNo'] && !props.dataObject.current.ClientDocumentNo}" :title="requiredFields['ClientDocumentNoError']" v-model="props.dataObject.current.ClientDocumentNo" @blur="props.dataObject.save()">
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Responsible && !requiredFields['Responsible'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Responsible || requiredFields['Responsible'])) && !hiddenFields.includes('Responsible')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Responsible')}}</label>
            <ODataLookup  :data-object="dsPersons" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.Responsible_ID = sel.ID, props.dataObject.current.Responsible = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['Responsible'] && !props.dataObject.current.Responsible}"
                v-model="props.dataObject.current.Responsible"
                :title="requiredFields['ResponsibleError']">
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="MobileNo" headername="MobileNo" width="100"></OColumn>
                <OColumn field="Email" headername="Email" width="100"></OColumn>
                <OColumn field="Represents" headername="Represents" width="100"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Delivered && !requiredFields['Delivered'])) &&(!props.requiredOrNotEmpty || props.dataObject.current.Delivered) && !hiddenFields.includes('Delivered')"  class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Delivered')}}</label>
            <input class="form-control form-control-sm" v-model="props.dataObject.current.Delivered" disabled>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ClassificationNameAndTitle && !requiredFields['ClassificationNameAndTitle'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ClassificationNameAndTitle || requiredFields['ClassificationCode'])) && !hiddenFields.includes('ClassificationCode')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Classification')}}</label>
            <ODataLookup  :data-object="dsClassificationCode" class="form-control form-control-sm"
                :bind="sel => { props.dataObject.current.ClassificationCode_ID = sel.ID, props.dataObject.current.ClassificationNameAndTitle = sel.NameAndTitle; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['ClassificationCode'] && !props.dataObject.current.ClassificationNameAndTitle}"
                v-model="props.dataObject.current.ClassificationNameAndTitle"
                :title="requiredFields['ClassificationCodeError']"
                :whereClause="classificationCodesLkpWhereClause()">
                <OColumn field="ID" headername="ID" width="100"></OColumn>
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="Title" headername="Title" width="100"></OColumn>
            </ODataLookup>
        </div>    

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Topic && !requiredFields['Topic'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Topic || requiredFields['Topic'])) && !hiddenFields.includes('Topic')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Topic')}}</label>
            <ODataLookup  :data-object="dsTopics" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['Topic'] && !props.dataObject.current.Topic}"
                v-model="props.dataObject.current.Topic"
                :title="requiredFields['TopicError']"
                :whereClause="topicsWhereclause()">
                <OColumn field="NameAndDescription" headername="NameandDescription" width="300"></OColumn>
            </ODataLookup>
        </div>   

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ProjectCode && !requiredFields['ProjectCode'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ProjectCode || requiredFields['ProjectCode'])) && !hiddenFields.includes('ProjectCode')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Project Code')}}</label>
            <ODataLookup  :data-object="dsProjectCodes" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['ProjectCode'] && !props.dataObject.current.ProjectCode}"
                v-model="props.dataObject.current.ProjectCode"
                :title="requiredFields['ProjectCodeError']"
                :whereClause="projectCodesLkpWhereClause()">
                <OColumn field="ID" headername="ID" width="100"></OColumn>
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="OrgUnit" headername="OrgUnit" width="100"></OColumn>
            </ODataLookup>
        </div>
        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.DisciplineNameAndTitle && !requiredFields['DisciplineNameAndTitle'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.DisciplineNameAndTitle || requiredFields['Discipline'])) && !hiddenFields.includes('Discipline')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Discipline')}}</label>
            <ODataLookup  :data-object="dsDisciplines" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['Discipline'] && !props.dataObject.current.Discipline}"
                v-model="props.dataObject.current.DisciplineNameAndTitle"
                :title="requiredFields['DisciplineError']"
                :whereClause="disciplinesLkpWhereClause()">
                <OColumn field="ID" headername="ID" width="100"></OColumn>
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="Title" headername="Title" width="100"></OColumn>
            </ODataLookup>
        </div>  

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ProjectPhase && !requiredFields['ProjectPhase'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ProjectPhase || requiredFields['ProjectPhase'])) && !hiddenFields.includes('ProjectPhase')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Project Phase')}}</label>
            <ODataLookup  :data-object="dsProjectPhases" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ProjectPhase_ID = sel.ID, props.dataObject.current.ProjectPhase = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['ProjectPhase'] && !props.dataObject.current.ProjectPhase}"
                v-model="props.dataObject.current.ProjectPhase"
                :title="requiredFields['ProjectPhaseError']">
                <OColumn field="ID" headername="ID" width="100"></OColumn>
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="Title" headername="Title" width="100"></OColumn>
            </ODataLookup>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.MainArea && !requiredFields['MainArea'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.MainArea || requiredFields['MainArea'])) && !hiddenFields.includes('MainArea')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Main Area')}}</label>
            <ODataLookup  :data-object="dsMainAreas" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['MainArea'] && !props.dataObject.current.MainArea}"
                v-model="props.dataObject.current.MainArea"
                :title="requiredFields['MainAreaError']"
                :whereClause="mainAreasLkpWhereClause()">
                <OColumn field="Name" headername="Name" width="100"></OColumn>
                <OColumn field="Title" headername="Title" width="100"></OColumn>
            </ODataLookup>
        </div>    

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.SubProjects && !requiredFields['SubProjects'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.SubProjects || requiredFields['SubProjects'])) && !hiddenFields.includes('SubProjects')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Sub Projects')}}</label>
            <ODataLookup  :data-object="dsSubProjects" class="form-control form-control-sm" multiselect
                :bind="row => selectCheckBoxValues(row,'SubProjects', 'dsSubProjects')"
                :height="400"
                :class="{'border border-danger':requiredFields['SubProjects'] && !props.dataObject.current.SubProjects}"
                :value="props.dataObject.current.SubProjects"
                :title="requiredFields['SubProjectsError']"
                :bindClear="() => selectCheckBoxValues(null,'SubProjects', 'dsSubProjects')"
                :whereClause="subProjectsWhereClause()">
                <OColumn field="Name" headername="Name" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.InvolvedContracts && !requiredFields['InvolvedContracts'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.InvolvedContracts || requiredFields['InvolvedContracts'])) && !hiddenFields.includes('InvolvedContracts')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Involved Contracts')}}</label>
            <ODataLookup  :data-object="dsOrgUnitsLkp" class="form-control form-control-sm" multiselect
                :bind="row => selectCheckBoxValues(row,'InvolvedContracts', 'dsOrgUnitsLkp')"
                :height="400"
                :class="{'border border-danger':requiredFields['InvolvedContracts'] && !props.dataObject.current.InvolvedContracts}"
                :value="props.dataObject.current.InvolvedContracts"
                :title="requiredFields['InvolvedContractsError']"
                :bindClear=" () => selectCheckBoxValues(null,'InvolvedContracts', 'dsOrgUnitsLkp')"
                :whereClause="involvedContractsWhereClause()">
                <OColumn field="NameAndTitle" headername="NameAndTitle" width="200"></OColumn>
                <OColumn field="UnitType" headername="UnitType" width="100"></OColumn>
                <OColumn field="Parent" headername="Parent" width="200"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ReviewClass && !requiredFields['ReviewClass'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ReviewClass || requiredFields['ReviewClass'])) && !hiddenFields.includes('ReviewClass')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Review Class')}}</label>
            <ODataLookup :data-object="dsReviewClasses" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ReviewClass_ID = sel.ID, props.dataObject.current.ReviewClass = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['ReviewClass'] && !props.dataObject.current.ReviewClass}"
                v-model="props.dataObject.current.ReviewClass"
                :title="requiredFields['ReviewClassError']">
                <OColumn field="ID" headername="ID" width="100"></OColumn>
                <OColumn field="Name" headername="Name" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Keywords && !requiredFields['Keywords'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Keywords || requiredFields['Keywords'])) && !hiddenFields.includes('Keywords')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Keywords')}}</label>
            <input  type="text" class="form-control form-control-sm" v-model="props.dataObject.current.Keywords" :class="{'border border-danger':requiredFields['Keywords'] && !props.dataObject.current.Keywords}" @blur="props.dataObject.save()">
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Progress && !requiredFields['Progress'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Progress || requiredFields['Progress'])) && !hiddenFields.includes('Progress')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Progress / Weighting')}}</label>
            <div class="d-flex">
                <input  type="text" class="form-control form-control-sm d-inline w-25"  v-model="props.dataObject.current.Progress" :class="{'border border-danger':requiredFields['Progress'] && !props.dataObject.current.Progress}" @blur="props.dataObject.save()">
                <input  type="text" class="form-control form-control-sm d-inline w-75 ms-1" v-model="props.dataObject.current.ProgressWeighting" :class="{'border border-danger':requiredFields['ProgressWeighting'] && !props.dataObject.current.ProgressWeighting}" @blur="props.dataObject.save()">
            </div>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.WBSNameAndTitle && !requiredFields['WBSNameAndTitle'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.WBSNameAndTitle || requiredFields['WBS'])) && !hiddenFields.includes('WBS')" class="form-group col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('WBS')}}</label>
            <ODataLookup :data-object="dsWBS" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.WBS_ID = sel.ID, props.dataObject.current.WBSNameAndTitle = sel.NameAndTitle; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['WBS'] && !props.dataObject.current.WBSNameAndTitle}"
                v-model="props.dataObject.current.WBSNameAndTitle"
                :title="requiredFields['WBS']">
                <OColumn field="Parent" :width="200"></OColumn>
                <OColumn field="Title" width="200"></OColumn>
                <OColumn field="Name" width="300"></OColumn>
            </ODataLookup>        
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  ((!props.dataObject.current.Comments || props.dataObject.current.Comments == '\n\n\n\n') && !requiredFields['Comments'])) &&(!props.requiredOrNotEmpty || ((props.dataObject.current.Comments && props.dataObject.current.Comments != '\n\n\n\n') || requiredFields['Comments'])) && !hiddenFields.includes('Comments')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Comments')}}</label>
            <OTextArea :renderHTML="true" rows="3" class="form-control form-control-sm"  @blur="props.dataObject.save()" v-model="props.dataObject.current.Comments"> </OTextArea>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.TransferredToOperations && !requiredFields['TransferredToOperations'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.TransferredToOperations || requiredFields['TransferredToOperations'])) && !hiddenFields.includes('TransferredToOperations')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Transferred To Operations')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.TransferredToOperations" :title="requiredFields['TransferredToOperations']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['TransferredToOperations'] && !props.dataObject.current.TransferredToOperations)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.NextDeliveryDate && !requiredFields['NextDeliveryDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.NextDeliveryDate || requiredFields['NextDeliveryDate'])) && !hiddenFields.includes('NextDeliveryDate')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Next Delivery Date')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.NextDeliveryDate" :title="requiredFields['NextDeliveryDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['NextDeliveryDate'] && !props.dataObject.current.NextDeliveryDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedIDC && !requiredFields['PlannedIDC'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedIDC || requiredFields['PlannedIDC'])) && !hiddenFields.includes('PlannedIDC')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned IDC')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedIDC" :title="requiredFields['PlannedIDCError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedIDC'] && !props.dataObject.current.PlannedIDC)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedIFR && !requiredFields['PlannedIFR'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedIFR || requiredFields['PlannedIFR'])) && !hiddenFields.includes('PlannedIFR')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned IFR')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedIFR" :title="requiredFields['PlannedIFRError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedIFR'] && !props.dataObject.current.PlannedIFR)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedIFU && !requiredFields['PlannedIFU'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedIFU || requiredFields['PlannedIFU'])) && !hiddenFields.includes('PlannedIFU')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned IFU/IFC')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedIFU" :title="requiredFields['PlannedIFUError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedIFU'] && !props.dataObject.current.PlannedIFU)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedASB && !requiredFields['PlannedASB'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedASB || requiredFields['PlannedASB'])) && !hiddenFields.includes('PlannedASB')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned ASB')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedASB" :title="requiredFields['PlannedASBError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedASB'] && !props.dataObject.current.PlannedASB)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedStart && !requiredFields['PlannedStart'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedStart || requiredFields['PlannedStart'])) && !hiddenFields.includes('PlannedStart')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned Start')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedStart" :title="requiredFields['PlannedStartError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedStart'] && !props.dataObject.current.PlannedStart)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedVerified && !requiredFields['PlannedVerified'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedVerified || requiredFields['PlannedVerified'])) && !hiddenFields.includes('PlannedVerified')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned Verified')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedVerified" :title="requiredFields['PlannedVerifiedError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedVerified'] && !props.dataObject.current.PlannedVerified)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedReleased && !requiredFields['PlannedReleased'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedReleased || requiredFields['PlannedReleased'])) && !hiddenFields.includes('PlannedReleased')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned Released')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.PlannedReleased" :title="requiredFields['PlannedReleasedError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['PlannedReleased'] && !props.dataObject.current.PlannedReleased)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastIDC && !requiredFields['ForecastIDC'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastIDC || requiredFields['ForecastIDC'])) && !hiddenFields.includes('ForecastIDC')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast IDC')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastIDC" :title="requiredFields['ForecastIDCError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastIDC'] && !props.dataObject.current.ForecastIDC)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastIFR && !requiredFields['ForecastIFR'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastIFR || requiredFields['ForecastIFR'])) && !hiddenFields.includes('ForecastIFR')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast IFR')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastIFR" :title="requiredFields['ForecastIFRError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastIFR'] && !props.dataObject.current.ForecastIFR)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastIFU && !requiredFields['ForecastIFU'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastIFU || requiredFields['ForecastIFU'])) && !hiddenFields.includes('ForecastIFU')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast IFU/IFC')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastIFU" :title="requiredFields['ForecastIFUError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastIFU'] && !props.dataObject.current.ForecastIFU)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastASB && !requiredFields['ForecastASB'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastASB || requiredFields['ForecastASB'])) && !hiddenFields.includes('ForecastASB')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast ASB')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastASB" :title="requiredFields['ForecastASBError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastASB'] && !props.dataObject.current.ForecastASB)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastStart && !requiredFields['ForecastStart'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastStart || requiredFields['ForecastStart'])) && !hiddenFields.includes('ForecastStart')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast Start')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastStart" :title="requiredFields['ForecastStartError']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastStart'] && !props.dataObject.current.ForecastStart)}"></o-date-picker>
        </div> 

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastVerified && !requiredFields['ForecastVerified'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastVerified || requiredFields['ForecastVerified'])) && !hiddenFields.includes('ForecastVerified')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast Verified')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastVerified" :title="requiredFields['ForecastVerified']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastVerified'] && !props.dataObject.current.ForecastVerified)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ForecastReleased && !requiredFields['ForecastReleased'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ForecastReleased || requiredFields['ForecastReleased'])) && !hiddenFields.includes('ForecastReleased')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Forecast Released')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ForecastReleased" :title="requiredFields['ForecastReleased']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ForecastReleased'] && !props.dataObject.current.ForecastReleased)}"></o-date-picker>
        </div>
        <template v-for="index in 15" :key="index">
            <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current['Meta'+index] && !requiredFields['Meta'+index])) &&(!props.requiredOrNotEmpty || (props.dataObject.current['Meta'+index] || requiredFields['Meta'+index])) && !hiddenFields.includes('Meta'+index)" class="form-group col-sm-4 col-6" :class="[props.customClass]">
                <label>{{dsMetaFields.current["Meta"+index]}}</label>
                <ODataLookup :data-object="dsMetaLookup" class="form-control form-control-sm"
                    :bind="sel => {props.dataObject.current['Meta'+index] = sel.ID ,props.dataObject.current['Meta'+index] = sel.Name; props.dataObject.save()}"
                    :height="400"
                    :class="{'border border-danger':requiredFields['Meta'+index] && !props.dataObject.current['Meta'+index]}"
                    v-model="props.dataObject.current['Meta'+index]"
                    :title="requiredFields['Meta'+index+'Error']"
                    :whereClause="getMetaWhereClause(index)">
                    <OColumn field="ID" headername="ID" width="100"></OColumn>
                    <OColumn field="Name" headername="Name" width="100"></OColumn>
                    <OColumn field="Description" headername="Description" width="100"></OColumn>
                </ODataLookup>
            </div> 
        </template>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExpiryTrackedBy && !requiredFields['ExpiryTrackedBy'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExpiryTrackedBy || requiredFields['ExpiryTrackedBy'])) && !hiddenFields.includes('ExpiryTrackedBy')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Expiry Tracked By')}}</label>
            <PersonLookup 
                :bind="sel => { props.dataObject.current.ExpiryTrackedBy_ID = sel.ID, props.dataObject.current.ExpiryTrackedBy = sel.Name; props.dataObject.save()}"
                class="form-control form-control-sm" 
                v-model="props.dataObject.current.ExpiryTrackedBy" 
                :title="requiredFields['ExpiryTrackedBy']" 
                :class="{'border border-danger':(requiredFields['ExpiryTrackedBy'] && !props.dataObject.current.ExpiryTrackedBy)}"
            ></PersonLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExpiryDate && !requiredFields['ExpiryDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExpiryDate || requiredFields['ExpiryDate'])) && !hiddenFields.includes('ExpiryDate')" class="planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Expiry Date')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ExpiryDate" :title="requiredFields['ExpiryDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ExpiryDate'] && !props.dataObject.current.ExpiryDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Person && !requiredFields['Person'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Person || requiredFields['Person'])) && !hiddenFields.includes('Person')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Person (Applies To)')}}</label>
            <PersonLookup 
                :bind="sel => { props.dataObject.current.Person_ID = sel.ID, props.dataObject.current.Person = sel.Name; props.dataObject.save()}"
                class="form-control form-control-sm" 
                v-model="props.dataObject.current.Person" 
                :title="requiredFields['Person']" 
                :class="{'border border-danger':(requiredFields['Person'] && !props.dataObject.current.Person)}"
            ></PersonLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.RetentionCode && !requiredFields['RetentionCode'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.RetentionCode || requiredFields['RetentionCode'])) && !hiddenFields.includes('RetentionCode')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Retention Code')}}</label>
            <ODataLookup :data-object="dsRetentionCodes" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.RetentionCode = sel.Code; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['RetentionCode'] && !props.dataObject.current.RetentionCode}"
                v-model="props.dataObject.current.RetentionCode"
                :title="requiredFields['RetentionCodeError']">
                <OColumn field="Code" width="130"></OColumn>
                <OColumn field="Description" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ArchivedDate && !requiredFields['ArchivedDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ArchivedDate || requiredFields['ArchivedDate'])) && !hiddenFields.includes('ArchivedDate')" class="planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Archived')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ArchivedDate" :title="requiredFields['ArchivedDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ArchivedDate'] && !props.dataObject.current.ArchivedDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ArchivedBy)) &&(!props.requiredOrNotEmpty || props.dataObject.current.ArchivedBy) && !hiddenFields.includes('ArchivedBy')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Archived By')}}</label>
            <input class="form-control form-control-sm"  v-model="props.dataObject.current.ArchivedBy" disabled>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Obsolete)) &&(!props.requiredOrNotEmpty || props.dataObject.current.Obsolete) && !hiddenFields.includes('Obsolete')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Obsolete')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.Obsolete" format="Short Date" :inputClassName="{'form-control form-control-sm':true}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ObsoleteReason)) &&(!props.requiredOrNotEmpty || props.dataObject.current.ObsoleteReason) && !hiddenFields.includes('ObsoleteReason')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Obsolete Reason')}}</label>
            <ODataLookup :data-object="dsVoidReasons" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ObsoleteReason_ID = sel.VoidReason_ID; props.dataObject.current.ObsoleteReason= sel.Name; props.dataObject.save()}"
                :height="400"
                v-model="props.dataObject.current.ObsoleteReason">
                <OColumn field="Name" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExternalProcessName && !requiredFields['ExternalProcessName'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExternalProcessName || requiredFields['ExternalProcessName'])) && !hiddenFields.includes('ExternalProcessName')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('External Process')}}</label>
            <ODataLookup :data-object="dsExtProcesses" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ExternalProcessName = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':(requiredFields['ExternalProcessName'] && !props.dataObject.current.ExternalProcessName)}"
                v-model="props.dataObject.current.ExternalProcessName">
                <OColumn field="Name" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExternalProcessStatus && !requiredFields['ExternalProcessStatus'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExternalProcessStatus || requiredFields['ExternalProcessStatus'])) && !hiddenFields.includes('ExternalProcessStatus')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('External Process Status')}}</label>
            <ODataLookup :data-object="dsExtProStatuses" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.ExternalProcessStatus = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':(requiredFields['ExternalProcessStatus'] && !props.dataObject.current.ExternalProcessStatus)}"
                v-model="props.dataObject.current.ExternalProcessStatus">
                <OColumn field="Name" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExternalProcessDueDate && !requiredFields['ExternalProcessDueDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExternalProcessDueDate || requiredFields['ExternalProcessDueDate'])) && !hiddenFields.includes('ExternalProcessDueDate')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('External Process Due Date')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ExternalProcessDueDate" :title="requiredFields['ExternalProcessDueDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ExternalProcessDueDate'] && !props.dataObject.current.ExternalProcessDueDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExternalProcessSentDate && !requiredFields['ExternalProcessSentDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExternalProcessSentDate || requiredFields['ExternalProcessSentDate'])) && !hiddenFields.includes('ExternalProcessSentDate')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('External Process Sent Date')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ExternalProcessSentDate" :title="requiredFields['ExternalProcessSentDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ExternalProcessSentDate'] && !props.dataObject.current.ExternalProcessSentDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.ExternalProcessReceivedDate && !requiredFields['ExternalProcessReceivedDate'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.ExternalProcessReceivedDate || requiredFields['ExternalProcessReceivedDate'])) && !hiddenFields.includes('ExternalProcessReceivedDate')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('External Process Received Date')}}</label>
            <o-date-picker @blur="props.dataObject.save()" v-model="props.dataObject.current.ExternalProcessReceivedDate" :title="requiredFields['ExternalProcessReceivedDate']" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger form-control form-control-sm':(requiredFields['ExternalProcessReceivedDate'] && !props.dataObject.current.ExternalProcessReceivedDate)}"></o-date-picker>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PackageNameAndTitle && !requiredFields['PackageNameAndTitle'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PackageNameAndTitle || requiredFields['PackageNameAndTitle'])) && !hiddenFields.includes('PackageNameAndTitle')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Document Package')}}</label>
            <ODataLookup :data-object="dsDocumentPackages" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.PackageNameAndTitle = sel.Name; props.dataObject.current.Package_ID = sel.ID; props.dataObject.save()}"
                :height="400"
                v-model="props.dataObject.current.PackageNameAndTitle"
                :class="{'border border-danger':(requiredFields['PackageNameAndTitle'] && !props.dataObject.current.PackageNameAndTitle)}"
                :whereClause="getDocumentPackagesWhereClause()">
                <OColumn field="Name" width="300"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.DistributionRequirements && !requiredFields['DistributionRequirements'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.DistributionRequirements || requiredFields['DistributionRequirements'])) && !hiddenFields.includes('DistributionRequirements')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Distribution Requirements')}}</label>
            <ODataLookup  :data-object="dsDistReqTypes" class="form-control form-control-sm" multiselect
                :bind="row => selectCheckBoxValues(row,'DistributionRequirements', 'dsDistReqTypes')"
                :height="400"
                :class="{'border border-danger':requiredFields['DistributionRequirements'] && !props.dataObject.current.DistributionRequirements}"
                :value="props.dataObject.current.DistributionRequirements"
                :title="requiredFields['DistributionRequirements']"
                :bindClear=" () => selectCheckBoxValues(null,'DistributionRequirements', 'dsDistReqTypes')">
                <OColumn field="Name" width="200"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.Company && !requiredFields['Company'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.Company || requiredFields['Company'])) && !hiddenFields.includes('Company')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Company')}}</label>
            <ODataLookup  :data-object="dsCompanies" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.Company = sel.Name; props.dataObject.current.Company_ID = sel.ID; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['Company'] && !props.dataObject.current.Company}"
                :value="props.dataObject.current.Company"
                :title="requiredFields['Company']">
                <OColumn field="ID" width="200"></OColumn>
                <OColumn field="Name" width="200"></OColumn>
                <OColumn field="CompanyNo" width="200"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.PlannedChange && !requiredFields['PlannedChange'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.PlannedChange || requiredFields['PlannedChange'])) && !hiddenFields.includes('PlannedChange')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Planned Change')}}</label>
            <ODataLookup  :data-object="dsPlannedChangeLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.PlannedChange = sel.Name; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['PlannedChange'] && !props.dataObject.current.PlannedChange}"
                :value="props.dataObject.current.PlannedChange"
                :title="requiredFields['PlannedChange']">
                <OColumn field="Name" width="150"></OColumn>
                <OColumn field="Description" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.URL && !requiredFields['URL'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.URL || requiredFields['URL'])) && !hiddenFields.includes('URL')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('URL')}}</label>
            <input @blur="props.dataObject.save()" type="text" class="form-control form-control-sm"  v-model="props.dataObject.current.URL">
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.AlternativeTitle && !requiredFields['AlternativeTitle'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.AlternativeTitle || requiredFields['AlternativeTitle'])) && !hiddenFields.includes('AlternativeTitle')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Alternative Title')}}</label>
            <input @blur="props.dataObject.save()" type="text" class="form-control form-control-sm"  v-model="props.dataObject.current.AlternativeTitle">
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.FreeText1 && !requiredFields['FreeText1'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.FreeText1 || requiredFields['FreeText1'])) && !hiddenFields.includes('FreeText1')" lass="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Free Text 1')}}</label>
            <ODataLookup  :data-object="dsFreeTextLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.FreeText1 = sel.FreeText; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['FreeText1'] && !props.dataObject.current.FreeText1}"
                :value="props.dataObject.current.FreeText1"
                :title="requiredFields['FreeText1']"
                :whereClause="'FreeTextNo=1'">
                <OColumn field="FreeText" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.FreeText2 && !requiredFields['FreeText2'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.FreeText2 || requiredFields['FreeText2'])) && !hiddenFields.includes('FreeText2')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Free Text 2')}}</label>
            <ODataLookup  :data-object="dsFreeTextLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.FreeText2 = sel.FreeText; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['FreeText2'] && !props.dataObject.current.FreeText2}"
                :value="props.dataObject.current.FreeText2"
                :title="requiredFields['FreeText2']"
                :whereClause="'FreeTextNo=2'">
                <OColumn field="FreeText" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.FreeText3 && !requiredFields['FreeText3'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.FreeText3 || requiredFields['FreeText3'])) && !hiddenFields.includes('FreeText3')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Free Text 3')}}</label>
            <ODataLookup  :data-object="dsFreeTextLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.FreeText3 = sel.FreeText; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['FreeText3'] && !props.dataObject.current.FreeText3}"
                :value="props.dataObject.current.FreeText3"
                :title="requiredFields['FreeText3']"
                :whereClause="'FreeTextNo=3'">
                <OColumn field="FreeText" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.FreeText4 && !requiredFields['FreeText4'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.FreeText4 || requiredFields['FreeText4'])) && !hiddenFields.includes('FreeText4')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Free Text 4')}}</label>
            <ODataLookup  :data-object="dsFreeTextLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.FreeText4 = sel.FreeText; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['FreeText4'] && !props.dataObject.current.FreeText4}"
                :value="props.dataObject.current.FreeText4"
                :title="requiredFields['FreeText4']"
                :whereClause="'FreeTextNo=4'">
                <OColumn field="FreeText" width="400"></OColumn>
            </ODataLookup>
        </div>

        <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current.FreeText5 && !requiredFields['FreeText5'])) &&(!props.requiredOrNotEmpty || (props.dataObject.current.FreeText5 || requiredFields['FreeText5'])) && !hiddenFields.includes('FreeText5')" class="form-group planned-select col-sm-4 col-6" :class="props.customClass">
            <label>{{$t('Free Text 5')}}</label>
            <ODataLookup  :data-object="dsFreeTextLkp" class="form-control form-control-sm"
                :bind="sel => {props.dataObject.current.FreeText5 = sel.FreeText; props.dataObject.save()}"
                :height="400"
                :class="{'border border-danger':requiredFields['FreeText5'] && !props.dataObject.current.FreeText5}"
                :value="props.dataObject.current.FreeText5"
                :title="requiredFields['FreeText5']"
                :whereClause="'FreeTextNo=5'">
                <OColumn field="FreeText" width="400"></OColumn>
            </ODataLookup>
        </div>
        <template v-for="code in dsCodeTypes.data" :key="code.index" >
            <div v-if="(!props.emptyAndNotRequired ||  (!props.dataObject.current['Code'+(code.index+1)] && !requiredFields['Code'+(code.index+1)])) &&(!props.requiredOrNotEmpty || (props.dataObject.current['Code'+(code.index+1)] || requiredFields['Code'+(code.index+1)+'Error'])) && !hiddenFields.includes('Code'+(code.index+1))" class="form-group col-sm-4 col-6 vField" :class="props.customClass">
                <label>{{code.Name}}</label>
                <ODataLookup draggable :data-object="dsCodesLookup" class="form-control form-control-sm"
                    :bind="sel => {props.dataObject.current['Code'+(code.index+1)+'_ID'] = sel.ID, props.dataObject.current['Code'+(code.index+1)] = sel.Name; props.dataObject.save()}"
                    :height="400"
                    :class="{'border border-danger':requiredFields['Code'+(code.index+1)] && !props.dataObject.current['Code'+(code.index+1)]}"
                    v-model="props.dataObject.current['Code'+(code.index+1)]"
                    :title="requiredFields['Code'+(code.index+1)+'Error']"
                    :whereClause="getCodesWhereClause(code.index+1)">
                    <OColumn field="ID" headername="ID" width="100"></OColumn>
                    <OColumn field="Name" headername="Name" width="100"></OColumn>
                    <OColumn field="Title" headername="Title" width="100"></OColumn>
                </ODataLookup>
            </div> 
        </template>
    </div>
</template>